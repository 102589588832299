.social {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;

  li {
    display: flex;
    width: 260px;
    height: 32px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #d0d5dd;
    background: #ffffff;
    transition: all 0.5s ease;
  }

  li:hover,
  li:active {
    border-radius: 8px;
    border: 1px solid #475467;
    background: #f8fafc;
  }

  li a {
    display: flex; /* Align children properly */
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
    width: 100%; /* Make the link fill the li */
    height: 100%; /* Make the link fill the li */
    text-decoration: none; /* Remove underline */
    color: inherit; /* Use the parent color */
    transition: color 0.3s ease; /* Smooth color transition */
  }

  li a:visited {
    color: inherit; /* Prevent visited links from changing color */
  }

  li a:hover {
    color: lightgray; /* Change color on hover */
  }

  li a:active {
    color: lightgray; /* Optional: Active state matches hover */
  }

  p {
    padding-left: 4px;
    margin: 0; /* Remove any default margin */
  }
}
