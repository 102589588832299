.my-cookie div:nth-child(2) {
  display: flex;
  flex-direction: row-reverse;
}

.my-cookie {
  font-family: "Nunito" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 15px 80px;
  box-shadow: 0px 24px 48px -12px rgba(0, 0, 0, 0.18);
}

.my-cookie a {
  text-decoration: underline;
  color: #5280FF;
  display: inline-block;
  margin: 0 5px;
}

@media screen and (max-width: 768.9px) {
  .my-cookie {
    padding: 15px 16px;
  }
}

@media screen and (max-width: 460.9px) {
  .my-cookie div:nth-child(2) {
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}