.title {
  margin: 0;
  padding: 21px 52px 21px 16px;
  font-size: 14px;
  font-weight: 600;
}

.withIcon {
  padding-left: 12px;
}

.close {
  position: absolute;
  right: 16px;
  top: 20px;
  width: 24px;
  min-width: initial;
  height: 24px;
  padding: 6px;
}

.actions {
  padding: 12px 16px;
}