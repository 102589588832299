.terms {
    padding: 80px 0 100px !important;
  }
  
  .terms .container {
    max-width: 1229px;
  }
  
  .terms .head {
    text-align: left;
    max-width: 650px;
  }
  
  .terms .head .h2 {
    font-weight: 400;
  }
  
  .terms .row {
    margin-bottom: 20px;
  }
  
  .terms .row:last-child {
    margin-bottom: 0;
  }
  
  .terms .row p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.4px;
    leading-trim: both;
  }
  
  @media screen and (max-width: 768.9px) {
    .terms {
      padding: 140px 0 40px !important;
    }
    .terms .row {
      margin-bottom: 60px;
    }
    .terms .row:last-child {
      margin-bottom: 0;
    }
  }
  