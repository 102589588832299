@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@700&display=swap');

body {
  margin: 0;
  font-family: 'Nunito', Times, serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-background);
}

code {
  font-family: 'Nunito', Times, serif;
}

.react-card-flipper {
  perspective: 1200px;
}
