.header {
  transition: all 0.5s ease;
  padding: 20px 0;
  top: 0;
}
.hidden {
  /* display: none; */
  position: fixed;
  z-index: 10;
  width: 100%;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.85);
  animation: fadeIn 0.5s ease;
}



@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.slide-in {
  animation: slideIn 0.5s ease;
}

@keyframes slideIn {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

@media screen and (max-width: 768.9px) {
  .header {
    transition: all 0.5s ease;
    padding: 8px 0;
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    background-color: #fff;
  }
  .hidden {
    animation: inherit;
  }
  
}