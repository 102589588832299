.swiper {
  overflow: unset;

  :global(.swiper-wrapper) {
    box-sizing: border-box;
  }
}

.swiperSlide {
  width: 247px;
}
