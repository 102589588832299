html,
body {
    overflow-x: hidden;
    width: 100%;
    scroll-behavior: smooth;
}

body {
    font-family: "Nunito", sans-serif;
    font-style: normal;
    margin: 0;
    min-width: 320px;
    min-height: 100vh;
    background: #ffffff;
    color: #344054;
    font-weight: 400;
    font-size: 16px;
}

.container {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 80px;
}

@media screen and (max-width: 1380px) {
    .container {
        max-width: 100%;
        margin: 0 auto;
        padding: 0 16px;
    }
}

.wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}
