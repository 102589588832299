.privacy {
  padding: 80px 0 100px !important;
}

.privacy .container {
  max-width: 1229px;
}

.privacy .head {
  text-align: left;
  max-width: 650px;
}

.privacy .head .h2 {
  font-weight: 400;
}

.privacy .row {
  margin-bottom: px;
}

.privacy .row:last-child {
  margin-bottom: 0;
}

.privacy .row p {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.4px;
  leading-trim: both;
}

@media screen and (max-width: 768.9px) {
  .privacy {
    padding: 140px 0 40px !important;
  }
  .privacy .row {
    margin-bottom: 60px;
  }
  .privacy .row:last-child {
    margin-bottom: 0;
  }
}
